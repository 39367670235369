import React from 'react';
import Loading from './Loading';

const ErrorMsg = ({ onClick }) => (
  <div className="content-wrapper flex-grow-1">
    <div className="text-center my-5">
      <h4>Przepraszamy, wystąpił błąd.</h4>
      <h4>Prosimy nacisnąć przycisk</h4>
      <button className="btn-primary btn-txt mt-3" onClick={onClick}>
        Odśwież
      </button>
    </div>
  </div>
);
const LoadableLoader = props => {
  if (props.error) {
    return <ErrorMsg onClick={() => window.location.reload()} />;
  } else if (props.timedOut) return <ErrorMsg onClick={window.location.reload()} />;
  else if (props.pastDelay) return <Loading />;
  else return null;
};

export default LoadableLoader;
