export default {
  home: '/',
  readings: '/katalog-lektur',
  reading: '/lektura',
  // authors: '/autorzy/',
  author: '/autor',
  project: '/projekt-szkolny',
  projects: '/projekty-szkolne',
  addProject: '/zglos-projekt',
  cooperation: '/wspolpraca',
  contact: '/kontakt',
  cookiesPolicy: '/polityka-cookies',
  notFound: '/strona-nie-istnieje',
  search: '/szukaj',
  epubReader: '/czytaj',
  category: '/kategoria'
};
