import Loadable from 'react-loadable';
import LoadableLoader from '../loading/LoadableLoader';

const AsyncLoader = getComponent =>
  Loadable({
    loader: getComponent,
    loading: LoadableLoader,
    delay: 300
  });

export default AsyncLoader;
