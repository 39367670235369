import React from 'react';

/*
    Adding new svg:
    - linearGradient id must be uniqe
    - xlink must be removed
    - className="circle-icon" must be added to every element with fill equal to #fff
*/

let oselab = (
  <svg xmlns="http://www.w3.org/2000/svg" width="78.571" height="25" viewBox="0 0 78.571 25">
    <defs>
      <linearGradient id="linear-gradient1" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#00d5b4" />
        <stop offset="1" stopColor="#00a1b7" />
      </linearGradient>
      <filter id="constantgrey">
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.3
                                      0 0 0 0 0.3
                                      0 0 0 0 0.3
                                      0 0 0 1 0"
        />
      </filter>
    </defs>
    <rect width="78.571" height="25" transform="translate(0 0)" className="circle-icon" fill="#fff" />
    <circle cx="11.219" cy="11.219" r="11.219" transform="translate(0 0)" fill="url(#linear-gradient1)" />
    <g transform="translate(25.743 13.733)">
      <path
        d="M100.974,597.919a1.731,1.731,0,0,1-1.859-1.822v-6.885H100.7V596c0,.379.159.575.563.575h.611v1.345Z"
        transform="translate(-99.115 -589.212)"
        fill="#242752"
      />
      <path
        d="M107.846,598.824v-.551a1.934,1.934,0,0,1-1.565.612,2.173,2.173,0,0,1-1.626-.563,2.042,2.042,0,0,1-.526-1.431c0-1.015.7-1.846,2.176-1.846h1.5v-.319c0-.7-.342-1-1.186-1a1.367,1.367,0,0,0-1.222.526l-1.015-.99a2.68,2.68,0,0,1,2.3-.881c1.785,0,2.715.759,2.715,2.251v4.194Zm-.036-2.691H106.55c-.575,0-.893.269-.893.721s.294.734.917.734a1.259,1.259,0,0,0,1-.305,1.047,1.047,0,0,0,.232-.807Z"
        transform="translate(-100.548 -590.117)"
        fill="#242752"
      />
      <path
        d="M118.585,597.418a2.214,2.214,0,0,1-1.552.574,1.939,1.939,0,0,1-1.591-.672v.6H113.9v-8.707h1.589v2.9a1.884,1.884,0,0,1,1.541-.636,2.218,2.218,0,0,1,1.552.575,5.879,5.879,0,0,1,0,5.369Zm-2.017-4.513c-.954,0-1.076.783-1.076,1.822s.122,1.834,1.076,1.834,1.076-.795,1.076-1.834S117.521,592.9,116.568,592.9Z"
        transform="translate(-103.34 -589.212)"
        fill="#242752"
      />
    </g>
    <path
      d="M136.564,574.4v1.861c0,.824-.585,1.2-1.356,1.2h-5.343v.93a2.114,2.114,0,0,0,2.206,2.047,1.637,1.637,0,0,0,1.6-.851,1.291,1.291,0,1,1,2.179,1.382,4.571,4.571,0,0,1-4.013,1.807,4.467,4.467,0,0,1-4.7-4.386v-4.04a4.733,4.733,0,0,1,9.436.054m-6.7.877h3.987v-.93a1.994,1.994,0,0,0-3.987,0Z"
      transform="translate(-81.376 -569.995)"
      fill="#242752"
    />
    <path
      d="M97.95,578.365v-3.933a4.728,4.728,0,0,1,9.436,0v3.933a4.728,4.728,0,0,1-9.436,0m2.711-3.933v3.933a1.942,1.942,0,0,0,2.02,1.968,1.92,1.92,0,0,0,1.993-1.968v-3.933a2,2,0,0,0-1.993-2.021,2.019,2.019,0,0,0-2.02,2.021"
      transform="translate(-73.039 -569.996)"
      fill="#242752"
    />
    <path
      d="M112.938,580.279c-.771-1.435,1.674-2.5,2.392-1.064a2.361,2.361,0,0,0,2.392,1.356,1.489,1.489,0,0,0,1.674-1.461c0-1.435-2.951-1.728-4.066-2.286a3.539,3.539,0,0,1-2.312-3.244c0-2.019,1.861-3.561,4.333-3.561a4.377,4.377,0,0,1,4.04,1.966c1.064,1.941-1.781,2.9-2.233,1.3-.212-.718-.9-.957-1.807-.957-.665,0-1.7.266-1.7,1.249,0,.425.186.771.851,1.089,2.074.957,5.635,1.356,5.635,4.439,0,2.472-2.154,3.774-4.333,3.774-2.339,0-3.907-.584-4.864-2.6"
      transform="translate(-77.279 -569.996)"
      fill="#242752"
    />
    <path
      d="M80.962,587.142l-1.733-3.551a1.79,1.79,0,0,1-.181-.783v-3.294a.49.49,0,0,0-.491-.49H75.965a.489.489,0,0,0-.489.489v3.3a1.78,1.78,0,0,1-.181.783l-1.732,3.551a.981.981,0,0,0,.881,1.411h5.637A.981.981,0,0,0,80.962,587.142ZM78.415,584.9a.413.413,0,1,1-.412-.413A.412.412,0,0,1,78.415,584.9Zm-.7-3.106a.412.412,0,1,1-.412.413A.413.413,0,0,1,77.717,581.791ZM76.68,582.91a.7.7,0,1,1-.7.7A.7.7,0,0,1,76.68,582.91Zm3.212,4.332a.686.686,0,0,1-.664.534h-3.9c-.794,0-.684-.749-.476-1.288.144-.374.652-1.794,1.232-1.376a4.162,4.162,0,0,0,.994.522,3.772,3.772,0,0,0,.841.211c.3.037.6.036.9.065a1.033,1.033,0,0,1,.739.351A1.33,1.33,0,0,1,79.892,587.242Z"
      transform="translate(-66.043 -572.569)"
      className="circle-icon"
      fill="#fff"
    />
  </svg>
);

let osehero = (
  <svg xmlns="http://www.w3.org/2000/svg" width="78.571" height="25" viewBox="0 0 78.571 25">
    <defs>
      <linearGradient id="linear-gradient2" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#fb0090" />
        <stop offset="1" stopColor="#a900a0" />
      </linearGradient>
    </defs>
    <rect width="78.571" height="25" transform="translate(0 0)" fill="none" />
    <g transform="translate(24.952 0)">
      <g transform="translate(0.894 13.709)">
        <path
          d="M274.294,108.013v-3.889a1.037,1.037,0,1,0-2.066,0v3.889h-1.589V99.306h1.589v2.923a2.106,2.106,0,0,1,1.529-.66,2.071,2.071,0,0,1,2.128,2.324v4.121Z"
          transform="translate(-270.638 -99.306)"
          fill="#242752"
        />
        <path
          d="M281.495,106.228a1.316,1.316,0,0,0,1.394,1.419,1.761,1.761,0,0,0,1.443-.6l.966.942a3.036,3.036,0,0,1-2.421,1c-1.5,0-2.947-.685-2.947-3.265,0-2.079,1.125-3.253,2.776-3.253a2.741,2.741,0,0,1,2.776,3.045v.709Zm2.275-1.773a1.188,1.188,0,0,0-2.128,0,1.767,1.767,0,0,0-.147.685h2.421A1.753,1.753,0,0,0,283.77,104.455Z"
          transform="translate(-273.293 -100.211)"
          fill="#242752"
        />
        <path
          d="M293.215,104.283a1.063,1.063,0,0,0-1.871.795v3.84h-1.59v-6.371h1.553v.612a2.156,2.156,0,0,1,1.6-.685,1.922,1.922,0,0,1,1.5.6Z"
          transform="translate(-276.1 -100.211)"
          fill="#242752"
        />
        <path
          d="M301.305,108.209a2.642,2.642,0,0,1-1.981.783,2.611,2.611,0,0,1-1.969-.783,4.611,4.611,0,0,1,0-4.953,2.611,2.611,0,0,1,1.969-.782,2.641,2.641,0,0,1,1.981.782,4.611,4.611,0,0,1,0,4.953Zm-1.235-4.023a1,1,0,0,0-.746-.281.976.976,0,0,0-.734.281,2.15,2.15,0,0,0-.367,1.541,2.186,2.186,0,0,0,.367,1.553.976.976,0,0,0,.734.281,1,1,0,0,0,.746-.281,2.185,2.185,0,0,0,.366-1.553A2.15,2.15,0,0,0,300.07,104.186Z"
          transform="translate(-278.065 -100.211)"
          fill="#242752"
        />
      </g>
      <path
        d="M308,84.5v1.86c0,.824-.585,1.2-1.356,1.2H301.3v.93a2.112,2.112,0,0,0,2.206,2.046,1.637,1.637,0,0,0,1.595-.85,1.291,1.291,0,1,1,2.179,1.382,4.571,4.571,0,0,1-4.014,1.807,4.468,4.468,0,0,1-4.705-4.386v-4.04A4.733,4.733,0,0,1,308,84.5m-6.7.877h3.987v-.93a1.994,1.994,0,0,0-3.987,0Z"
        transform="translate(-277.723 -80.113)"
        fill="#242752"
      />
      <path
        d="M269.387,88.46V84.526a4.728,4.728,0,0,1,9.436,0V88.46a4.728,4.728,0,0,1-9.436,0m2.711-3.934V88.46a1.942,1.942,0,0,0,2.02,1.968,1.92,1.92,0,0,0,1.993-1.968V84.526a2,2,0,0,0-1.993-2.02,2.019,2.019,0,0,0-2.02,2.02"
        transform="translate(-269.387 -80.113)"
        fill="#242752"
      />
      <path
        d="M284.375,90.374c-.771-1.435,1.674-2.5,2.392-1.064a2.361,2.361,0,0,0,2.392,1.356,1.489,1.489,0,0,0,1.674-1.461c0-1.436-2.951-1.728-4.066-2.286a3.538,3.538,0,0,1-2.312-3.243c0-2.019,1.86-3.561,4.333-3.561a4.377,4.377,0,0,1,4.04,1.966c1.064,1.94-1.781,2.9-2.233,1.3-.213-.718-.9-.957-1.807-.957-.665,0-1.7.266-1.7,1.249,0,.425.186.771.851,1.089,2.074.957,5.635,1.356,5.635,4.439,0,2.472-2.154,3.774-4.333,3.774-2.339,0-3.907-.584-4.864-2.6"
        transform="translate(-273.627 -80.113)"
        fill="#242752"
      />
    </g>
    <circle cx="11.219" cy="11.219" r="11.219" transform="translate(0 0.001)" fill="url(#linear-gradient2)" />
    <g transform="translate(6.841 6.456)">
      <rect
        width="5.575"
        height="0.476"
        rx="0.163"
        transform="translate(1.637 9.053)"
        className="circle-icon"
        fill="#fff"
      />
      <path
        d="M252.149,89.726h-1.041c0-.144,0-.227,0-.227a.348.348,0,0,0-.348-.348h-4.6a.348.348,0,0,0-.348.348s0,.084,0,.227h-1.134a.645.645,0,0,0-.639.65V90.4c.017.17.436,4,2.651,4.343a1.536,1.536,0,0,0,.82.715.136.136,0,0,0,.091.229v.79h-.972a.273.273,0,0,0-.272.272v1.126h4.211V96.752a.273.273,0,0,0-.272-.272h-.972v-.79a.136.136,0,0,0,.091-.229,1.548,1.548,0,0,0,.829-.731c2.126-.439,2.531-4.159,2.548-4.326v-.026A.645.645,0,0,0,252.149,89.726Zm-7.6.639a.122.122,0,0,1,.117-.117h1.138a13,13,0,0,0,.574,3.866C244.944,93.46,244.582,90.605,244.554,90.365Zm6,3.692a13.192,13.192,0,0,0,.554-3.809h1.044a.122.122,0,0,1,.117.117C252.239,90.6,251.9,93.309,250.551,94.057Z"
        transform="translate(-244.032 -89.151)"
        className="circle-icon"
        fill="#fff"
      />
    </g>
  </svg>
);

let oseinspiracje = (
  <svg xmlns="http://www.w3.org/2000/svg" width="78.571" height="25" viewBox="0 0 78.571 25">
    <defs>
      <linearGradient id="linear-gradient3" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#ff6e2c" />
        <stop offset="1" stopColor="#d100cd" />
      </linearGradient>
    </defs>
    <rect width="78.571" height="25" transform="translate(0 0)" fill="none" />
    <g transform="translate(0 0)">
      <circle cx="11.202" cy="11.202" r="11.202" fill="url(#linear-gradient3)" />
      <path
        d="M77.709,460.106a2.5,2.5,0,0,0-2.5,2.5c0,1.572,1.489,1.876,1.489,2.607l.006.417a.281.281,0,0,0,.28.28h1.479a.281.281,0,0,0,.28-.28l0-.423c0-.726,1.47-1.029,1.47-2.6A2.5,2.5,0,0,0,77.709,460.106Z"
        transform="translate(-66.593 -451.518)"
        className="circle-icon"
        fill="#fff"
      />
      <rect
        width="2.041"
        height="0.973"
        rx="0.455"
        transform="translate(10.105 15.016)"
        className="circle-icon"
        fill="#fff"
      />
      <g transform="translate(6.416 6.449)">
        <g transform="translate(6.754 6.915)">
          <path
            d="M82.433,467.913a.394.394,0,0,1-.226-.072l-.412-.29a.395.395,0,0,1,.454-.646l.412.29a.4.4,0,0,1-.228.718Z"
            transform="translate(-81.626 -466.834)"
            className="circle-icon"
            fill="#fff"
          />
        </g>
        <g transform="translate(0 4.362)">
          <path
            d="M72.959,464.027h-.451a.395.395,0,0,1,0-.789h.451a.395.395,0,0,1,0,.789Z"
            transform="translate(-72.113 -463.238)"
            className="circle-icon"
            fill="#fff"
          />
        </g>
        <g transform="translate(1.442 6.901)">
          <path
            d="M74.54,467.907a.395.395,0,0,1-.229-.716l.427-.3a.395.395,0,0,1,.459.643l-.427.3A.4.4,0,0,1,74.54,467.907Z"
            transform="translate(-74.145 -466.814)"
            className="circle-icon"
            fill="#fff"
          />
        </g>
        <g transform="translate(7.172 1.295)">
          <path
            d="M82.611,460.008a.394.394,0,0,1-.279-.674l.3-.3a.395.395,0,1,1,.557.559l-.3.3A.4.4,0,0,1,82.611,460.008Z"
            transform="translate(-82.216 -458.917)"
            className="circle-icon"
            fill="#fff"
          />
        </g>
        <g transform="translate(8.106 4.362)">
          <path
            d="M84.428,464.027h-.5a.395.395,0,1,1,0-.789h.5a.395.395,0,0,1,0,.789Z"
            transform="translate(-83.531 -463.238)"
            className="circle-icon"
            fill="#fff"
          />
        </g>
        <g transform="translate(1.134 1.295)">
          <path
            d="M74.406,460.008a.4.4,0,0,1-.279-.115l-.3-.3a.395.395,0,0,1,.558-.559l.3.3a.395.395,0,0,1-.279.674Z"
            transform="translate(-73.71 -458.917)"
            className="circle-icon"
            fill="#fff"
          />
        </g>
        <g transform="translate(4.304)">
          <path
            d="M78.57,458.384a.394.394,0,0,1-.394-.395v-.5a.394.394,0,0,1,.789,0v.5A.394.394,0,0,1,78.57,458.384Z"
            transform="translate(-78.176 -457.093)"
            className="circle-icon"
            fill="#fff"
          />
        </g>
      </g>
    </g>
    <g transform="translate(24.879 0.085)">
      <g transform="translate(0.863 13.577)">
        <path
          d="M99.336,468.542v-1.289h1.616v1.289Zm.024,7.414v-6.332h1.58v6.332Z"
          transform="translate(-99.336 -467.253)"
          fill="#242752"
        />
        <path
          d="M107.759,476.894v-3.828a1.056,1.056,0,1,0-2.1,0v3.828h-1.58v-6.332h1.543v.584a2.165,2.165,0,0,1,1.58-.657,2.056,2.056,0,0,1,1.494.559,2.346,2.346,0,0,1,.645,1.8v4.047Z"
          transform="translate(-100.711 -468.192)"
          fill="#242752"
        />
        <path
          d="M115.708,476.967a3.444,3.444,0,0,1-2.7-.912l1.034-1.034a2.3,2.3,0,0,0,1.689.6c.547,0,1.118-.182,1.118-.655,0-.317-.17-.535-.668-.584l-1-.1c-1.142-.11-1.847-.608-1.847-1.775,0-1.313,1.154-2.018,2.443-2.018a3.361,3.361,0,0,1,2.419.741l-.973.984a2.2,2.2,0,0,0-1.471-.424c-.632,0-.9.292-.9.607,0,.231.1.5.656.547l1,.1c1.252.121,1.884.79,1.884,1.86C118.395,476.3,117.2,476.967,115.708,476.967Z"
          transform="translate(-103.303 -468.192)"
          fill="#242752"
        />
        <path
          d="M127.113,476.4a2.2,2.2,0,0,1-1.543.572,1.874,1.874,0,0,1-1.532-.632v2.88h-1.58v-8.654h1.531v.6a1.926,1.926,0,0,1,1.58-.668,2.207,2.207,0,0,1,1.543.571,5.849,5.849,0,0,1,0,5.336Zm-2.006-4.485c-.948,0-1.07.777-1.07,1.81s.121,1.824,1.07,1.824,1.07-.79,1.07-1.824S126.055,471.911,125.107,471.911Z"
          transform="translate(-106.043 -468.192)"
          fill="#242752"
        />
        <path
          d="M132.007,468.542v-1.289h1.617v1.289Zm.025,7.414v-6.332h1.58v6.332Z"
          transform="translate(-108.813 -467.253)"
          fill="#242752"
        />
        <path
          d="M140.188,472.288a1.056,1.056,0,0,0-1.86.789v3.817h-1.58v-6.332h1.543v.608a2.143,2.143,0,0,1,1.592-.681,1.907,1.907,0,0,1,1.494.6Z"
          transform="translate(-110.188 -468.192)"
          fill="#242752"
        />
        <path
          d="M147.425,476.894v-.547a1.924,1.924,0,0,1-1.555.608,2.159,2.159,0,0,1-1.617-.559,2.027,2.027,0,0,1-.523-1.422c0-1.009.693-1.835,2.164-1.835h1.5v-.316c0-.693-.341-1-1.179-1a1.36,1.36,0,0,0-1.215.523l-1.009-.985a2.663,2.663,0,0,1,2.285-.875c1.775,0,2.7.753,2.7,2.236v4.169Zm-.036-2.674h-1.252c-.571,0-.887.268-.887.716s.292.73.912.73a1.251,1.251,0,0,0,1-.3,1.04,1.04,0,0,0,.231-.8Z"
          transform="translate(-112.214 -468.192)"
          fill="#242752"
        />
        <path
          d="M155.889,476.967c-1.264,0-2.832-.681-2.832-3.246s1.568-3.232,2.832-3.232a2.7,2.7,0,0,1,2.091.85l-1.069,1.07a1.281,1.281,0,0,0-1.022-.5,1.094,1.094,0,0,0-.9.413,2.142,2.142,0,0,0-.353,1.4,2.182,2.182,0,0,0,.353,1.411,1.1,1.1,0,0,0,.9.413,1.279,1.279,0,0,0,1.022-.5l1.069,1.057A2.707,2.707,0,0,1,155.889,476.967Z"
          transform="translate(-114.919 -468.192)"
          fill="#242752"
        />
        <path
          d="M160.974,478.326h-.9v-1.338h.608c.4,0,.559-.194.559-.571v-6.795h1.58v6.891A1.721,1.721,0,0,1,160.974,478.326Zm.243-9.784v-1.289h1.617v1.289Z"
          transform="translate(-116.955 -467.253)"
          fill="#242752"
        />
        <path
          d="M167.534,474.22a1.308,1.308,0,0,0,1.386,1.41,1.754,1.754,0,0,0,1.434-.6l.96.936a3.013,3.013,0,0,1-2.406,1c-1.5,0-2.929-.681-2.929-3.246,0-2.066,1.118-3.232,2.759-3.232a2.723,2.723,0,0,1,2.759,3.026v.706Zm2.261-1.763a1.18,1.18,0,0,0-2.115,0,1.746,1.746,0,0,0-.146.681h2.407A1.745,1.745,0,0,0,169.8,472.458Z"
          transform="translate(-118.668 -468.192)"
          fill="#242752"
        />
      </g>
      <path
        d="M136.677,452.488v1.849c0,.819-.581,1.188-1.347,1.188h-5.31v.924a2.1,2.1,0,0,0,2.192,2.035,1.629,1.629,0,0,0,1.586-.845,1.283,1.283,0,1,1,2.166,1.373,4.542,4.542,0,0,1-3.989,1.8,4.44,4.44,0,0,1-4.676-4.359v-4.015a4.7,4.7,0,0,1,9.379.053m-6.657.872h3.962v-.924a1.982,1.982,0,0,0-3.962,0Z"
        transform="translate(-106.584 -448.128)"
        fill="#242752"
      />
      <path
        d="M98.12,456.424v-3.91a4.7,4.7,0,0,1,9.379,0v3.91a4.7,4.7,0,0,1-9.379,0m2.695-3.91v3.91a1.93,1.93,0,0,0,2.008,1.955,1.908,1.908,0,0,0,1.981-1.955v-3.91a1.984,1.984,0,0,0-1.981-2.008,2.007,2.007,0,0,0-2.008,2.008"
        transform="translate(-98.12 -448.128)"
        fill="#242752"
      />
      <path
        d="M113.107,458.326c-.766-1.426,1.664-2.483,2.378-1.056a2.348,2.348,0,0,0,2.378,1.347,1.48,1.48,0,0,0,1.665-1.453c0-1.426-2.933-1.717-4.042-2.272a3.518,3.518,0,0,1-2.3-3.223c0-2.007,1.849-3.54,4.306-3.54a4.353,4.353,0,0,1,4.016,1.955c1.056,1.928-1.77,2.879-2.22,1.295-.211-.714-.9-.952-1.8-.952-.66,0-1.691.265-1.691,1.242,0,.423.185.767.846,1.083,2.061.951,5.6,1.347,5.6,4.412,0,2.456-2.14,3.751-4.306,3.751-2.326,0-3.884-.581-4.835-2.589"
        transform="translate(-102.424 -448.128)"
        fill="#242752"
      />
    </g>
  </svg>
);
let oselektury = (
  <svg xmlns="http://www.w3.org/2000/svg" width="78.571" height="25" viewBox="0 0 78.571 25">
    <defs>
      <linearGradient id="linear-gradient4" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#00a7e2" />
        <stop offset="1" stopColor="#004e9b" />
      </linearGradient>
    </defs>
    <rect width="78.571" height="25" transform="translate(0 0)" fill="none" />
    <g transform="translate(0 0)">
      <circle cx="11.241" cy="11.241" r="11.241" transform="translate(0 0.101)" fill="url(#linear-gradient4)" />
      <g transform="translate(4.843 6.569)">
        <path
          d="M73.012,99.326a6.143,6.143,0,0,0-1.466.2,1.9,1.9,0,0,1-.44.076.593.593,0,0,1-.611-.588V92.514h-.284a.469.469,0,0,0-.469.469V99.9a.469.469,0,0,0,.469.469,34.776,34.776,0,0,1,4.744-.6,5.316,5.316,0,0,0-1.108-.352A4.166,4.166,0,0,0,73.012,99.326Z"
          transform="translate(-69.743 -90.819)"
          className="circle-icon"
          fill="#fff"
        />
        <path
          d="M78.574,90.449a3.463,3.463,0,0,0-1.9,1.387h-.01a3.441,3.441,0,0,0-1.9-1.387,7.743,7.743,0,0,0-3.179-.221v8.112c0,.006.006.013.016.016a.022.022,0,0,1,.013,0h.022c.106,0,.381-.089.771-.163l.051-.01c.054-.009.109-.022.166-.029a.773.773,0,0,1,.08-.013c.1-.016.2-.029.3-.039l.111-.009.166-.009c.048,0,.1,0,.144-.006h.153c.125,0,.25.006.381.019a.562.562,0,0,0,.077.006c.144.016.294.039.444.067.166.036.323.074.467.116a.108.108,0,0,0,.025.006c.138.041.266.083.384.128l.048.019c.111.041.217.086.313.128l.039.019c.09.042.176.084.253.125.006,0,.009,0,.016.006.074.039.141.076.2.112.147.086.256.156.326.191h0a.2.2,0,0,0,.077.026H76.7a.18.18,0,0,0,.07-.026.014.014,0,0,0,.01,0c.029-.016.07-.039.115-.067s.1-.058.156-.093c.013-.006.029-.016.045-.026.061-.035.128-.074.2-.115l.019-.009c.074-.039.157-.08.246-.122l.045-.019c.093-.042.2-.086.31-.128l.051-.019c.118-.045.243-.086.38-.128a.08.08,0,0,0,.026-.006c.144-.042.3-.08.467-.116s.3-.051.444-.067c.026,0,.051-.006.077-.006.131-.012.256-.019.38-.019H79.9c.051,0,.1,0,.15.006l.16.009c.048,0,.089.006.134.013.1.009.189.019.275.032.032.006.064.009.093.016.054.009.109.016.16.026a.5.5,0,0,0,.064.013c.387.076.659.16.764.163h.006a.031.031,0,0,0,.016,0h.013a.022.022,0,0,0,.013-.016V90.229A7.725,7.725,0,0,0,78.574,90.449ZM78.1,94.67l-2.76,1.775a.087.087,0,0,1-.054.016.09.09,0,0,1-.1-.086V92.819a.087.087,0,0,1,.016-.048.1.1,0,0,1,.134-.026l2.76,1.778a.082.082,0,0,1,.022.022A.086.086,0,0,1,78.1,94.67Z"
          transform="translate(-70.271 -90.141)"
          className="circle-icon"
          fill="#fff"
        />
        <path
          d="M85.1,92.514h-.281v6.505a.6.6,0,0,1-.611.588,1.879,1.879,0,0,1-.441-.076,6.142,6.142,0,0,0-1.469-.2,4.2,4.2,0,0,0-.835.082,5.328,5.328,0,0,0-1.111.355,35.821,35.821,0,0,1,4.747.6.469.469,0,0,0,.469-.469V92.983A.469.469,0,0,0,85.1,92.514Z"
          transform="translate(-72.776 -90.819)"
          className="circle-icon"
          fill="#fff"
        />
      </g>
      <path
        d="M136.6,85.339V87.2c0,.826-.586,1.2-1.359,1.2h-5.354v.932a2.117,2.117,0,0,0,2.211,2.051,1.641,1.641,0,0,0,1.6-.852,1.293,1.293,0,1,1,2.183,1.385,4.578,4.578,0,0,1-4.021,1.811,4.476,4.476,0,0,1-4.714-4.394V85.285a4.742,4.742,0,0,1,9.455.054m-6.711.879h4v-.931a2,2,0,0,0-4,0Z"
        transform="translate(-81.3 -80.944)"
        fill="#242752"
      />
      <path
        d="M97.907,89.307V85.366a4.738,4.738,0,0,1,9.454,0v3.941a4.738,4.738,0,0,1-9.454,0m2.716-3.941v3.941a1.946,1.946,0,0,0,2.024,1.971,1.924,1.924,0,0,0,2-1.971V85.366a2,2,0,0,0-2-2.024,2.023,2.023,0,0,0-2.024,2.024"
        transform="translate(-72.947 -80.944)"
        fill="#242752"
      />
      <path
        d="M112.923,91.225c-.772-1.438,1.677-2.5,2.4-1.065a2.365,2.365,0,0,0,2.4,1.358,1.492,1.492,0,0,0,1.678-1.464c0-1.438-2.956-1.731-4.074-2.291A3.544,3.544,0,0,1,113,84.514c0-2.024,1.864-3.569,4.341-3.569a4.385,4.385,0,0,1,4.048,1.971c1.066,1.944-1.784,2.9-2.237,1.3-.213-.719-.9-.959-1.811-.959-.666,0-1.7.266-1.7,1.252,0,.426.187.772.853,1.091,2.077.959,5.646,1.358,5.646,4.448,0,2.476-2.157,3.781-4.341,3.781-2.344,0-3.915-.585-4.874-2.609"
        transform="translate(-77.195 -80.944)"
        fill="#242752"
      />
    </g>
    <g transform="translate(25.088 13.625)">
      <path
        d="M99.948,108.74a1.734,1.734,0,0,1-1.861-1.825v-6.9h1.592v6.8c0,.38.159.576.564.576h.612v1.347Z"
        transform="translate(-98.087 -100.019)"
        fill="#242752"
      />
      <path
        d="M104.439,106.952a1.318,1.318,0,0,0,1.4,1.421,1.765,1.765,0,0,0,1.445-.6l.968.943a3.038,3.038,0,0,1-2.425,1c-1.506,0-2.952-.686-2.952-3.27,0-2.082,1.127-3.259,2.781-3.259a2.745,2.745,0,0,1,2.78,3.05v.711Zm2.279-1.776a1.189,1.189,0,0,0-2.131,0,1.761,1.761,0,0,0-.147.686h2.425A1.761,1.761,0,0,0,106.717,105.175Z"
        transform="translate(-99.454 -100.925)"
        fill="#242752"
      />
      <path
        d="M116.548,108.74l-1.568-2.658-.674.759v1.9h-1.592v-8.721h1.592v4.949l2.119-2.609h1.923l-2.279,2.572,2.45,3.809Z"
        transform="translate(-102.266 -100.019)"
        fill="#242752"
      />
      <path
        d="M124.085,108.995a1.731,1.731,0,0,1-1.849-1.825v-3.16h-.674V102.8h.674v-1.886h1.592V102.8h1.127v1.212h-1.127v3.062c0,.367.171.576.551.576h.576v1.347Z"
        transform="translate(-104.794 -100.274)"
        fill="#242752"
      />
      <path
        d="M131.98,109.675v-.588a2.184,2.184,0,0,1-1.592.661,2.07,2.07,0,0,1-1.506-.564,2.364,2.364,0,0,1-.649-1.812v-4.079h1.592v3.859a1.064,1.064,0,1,0,2.119,0v-3.859h1.592v6.381Z"
        transform="translate(-106.7 -100.955)"
        fill="#242752"
      />
      <path
        d="M141.627,105a1.065,1.065,0,0,0-1.874.8v3.846h-1.592v-6.381h1.556v.613a2.158,2.158,0,0,1,1.6-.686,1.924,1.924,0,0,1,1.506.6Z"
        transform="translate(-109.537 -100.925)"
        fill="#242752"
      />
      <path
        d="M148,110.7a2.271,2.271,0,0,1-.477.821,1.848,1.848,0,0,1-1.372.49h-.625v-1.434h.368a.689.689,0,0,0,.8-.576l.269-.784-2.181-5.929h1.679l1.323,3.944,1.274-3.944h1.678Z"
        transform="translate(-111.428 -100.955)"
        fill="#242752"
      />
    </g>
  </svg>
);
let oselabtrs = (
  <svg xmlns="http://www.w3.org/2000/svg" width="78.571" height="25" viewBox="0 0 78.571 25">
    <defs>
      <linearGradient id="linear-gradient5" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#00c0d1" />
        <stop offset="1" stopColor="#485cb3" />
      </linearGradient>
    </defs>
    <rect width="78.571" height="25" transform="translate(0 0)" fill="none" />
    <g transform="translate(0 0.033)">
      <circle cx="11.219" cy="11.219" r="11.219" fill="url(#linear-gradient5)" />
      <path
        d="M251.425,336.913a.487.487,0,0,1-.689,0l-.473-.473a.487.487,0,0,1,0-.689l.865-.865a.22.22,0,0,0-.14-.376,1.807,1.807,0,0,0-1.8,2.441l-3.775,3.775a1.806,1.806,0,0,0-2.44,1.8.219.219,0,0,0,.374.139l.866-.866a.488.488,0,0,1,.69,0l.473.473a.488.488,0,0,1,0,.689l-.881.88a.22.22,0,0,0,.137.374,1.807,1.807,0,0,0,1.836-2.439l3.775-3.775a1.806,1.806,0,0,0,2.439-1.836.22.22,0,0,0-.374-.137Z"
        transform="translate(-236.886 -328.147)"
        className="circle-icon"
        fill="#fff"
      />
      <path
        d="M250.051,340.369l-4.176-4.176.149-.617-1.379-1.013-.83.83,1.013,1.379.6-.144,4.185,4.184a.941.941,0,0,0,.184,1.071l2.042,2.041a.94.94,0,0,0,1.33-1.329l-2.042-2.042A.94.94,0,0,0,250.051,340.369Z"
        transform="translate(-237.129 -328.163)"
        className="circle-icon"
        fill="#fff"
      />
    </g>
    <g transform="translate(24.928 0)">
      <g transform="translate(0.831 13.636)">
        <path
          d="M272.376,353.456a1.731,1.731,0,0,1-1.859-1.822v-6.886h1.59v6.787c0,.38.159.575.562.575h.611v1.346Z"
          transform="translate(-270.517 -344.675)"
          fill="#242752"
        />
        <path
          d="M279.249,354.361v-.551a1.937,1.937,0,0,1-1.566.611,2.177,2.177,0,0,1-1.626-.562,2.042,2.042,0,0,1-.526-1.431c0-1.015.7-1.846,2.176-1.846h1.5v-.318c0-.7-.342-1-1.186-1a1.367,1.367,0,0,0-1.223.526l-1.015-.991a2.678,2.678,0,0,1,2.3-.881c1.786,0,2.715.759,2.715,2.251v4.194Zm-.037-2.691h-1.259c-.574,0-.893.269-.893.721s.294.734.917.734a1.261,1.261,0,0,0,1-.305,1.047,1.047,0,0,0,.232-.807Z"
          transform="translate(-271.95 -345.58)"
          fill="#242752"
        />
        <path
          d="M289.988,352.954a2.219,2.219,0,0,1-1.553.574,1.937,1.937,0,0,1-1.59-.672v.6H285.3v-8.708h1.59v2.9a1.884,1.884,0,0,1,1.541-.636,2.216,2.216,0,0,1,1.553.575,5.885,5.885,0,0,1,0,5.369Zm-2.018-4.513c-.954,0-1.076.783-1.076,1.822s.122,1.835,1.076,1.835,1.076-.8,1.076-1.835S288.923,348.442,287.97,348.442Z"
          transform="translate(-274.742 -344.675)"
          fill="#242752"
        />
        <path
          d="M298.126,346.265v7.191h-1.7v-7.191h-2.274v-1.517H300.4v1.517Z"
          transform="translate(-277.27 -344.675)"
          fill="#242752"
        />
        <path
          d="M309.454,353.456l-1.7-3.473h-1.223v3.473h-1.7v-8.708h3.412a2.633,2.633,0,0,1,2.825,2.666,2.346,2.346,0,0,1-1.59,2.287l1.944,3.754Zm-1.321-7.191h-1.6v2.3h1.6a1.152,1.152,0,1,0,0-2.3Z"
          transform="translate(-280.321 -344.675)"
          fill="#242752"
        />
        <path
          d="M318.108,353.5a4.087,4.087,0,0,1-3.179-1.149l1.1-1.1a2.847,2.847,0,0,0,2.1.734c1,0,1.541-.379,1.541-1.076a.97.97,0,0,0-.269-.734,1.308,1.308,0,0,0-.771-.306l-1.051-.146a2.951,2.951,0,0,1-1.688-.721,2.3,2.3,0,0,1-.624-1.712c0-1.553,1.149-2.641,3.033-2.641a3.732,3.732,0,0,1,2.813,1.015l-1.076,1.064a2.424,2.424,0,0,0-1.786-.6c-.9,0-1.345.5-1.345,1.1a.838.838,0,0,0,.257.624,1.5,1.5,0,0,0,.807.343l1.027.147a2.878,2.878,0,0,1,1.663.672,2.437,2.437,0,0,1,.673,1.846C321.337,352.546,319.93,353.5,318.108,353.5Z"
          transform="translate(-283.206 -344.646)"
          fill="#242752"
        />
      </g>
      <path
        d="M307.966,329.942v1.86c0,.824-.585,1.2-1.356,1.2h-5.343v.93a2.112,2.112,0,0,0,2.206,2.046,1.639,1.639,0,0,0,1.6-.85,1.291,1.291,0,1,1,2.179,1.382,4.572,4.572,0,0,1-4.014,1.807,4.467,4.467,0,0,1-4.705-4.386v-4.04a4.733,4.733,0,0,1,9.436.054m-6.7.877h3.986v-.93a1.994,1.994,0,0,0-3.986,0Z"
        transform="translate(-277.689 -325.556)"
        fill="#242752"
      />
      <path
        d="M269.353,333.9v-3.934a4.728,4.728,0,0,1,9.436,0V333.9a4.728,4.728,0,0,1-9.436,0m2.711-3.934V333.9a1.942,1.942,0,0,0,2.02,1.968,1.92,1.92,0,0,0,1.994-1.968v-3.934a2,2,0,0,0-1.994-2.02,2.019,2.019,0,0,0-2.02,2.02"
        transform="translate(-269.353 -325.556)"
        fill="#242752"
      />
      <path
        d="M284.34,335.817c-.771-1.435,1.674-2.5,2.392-1.064a2.361,2.361,0,0,0,2.392,1.356,1.489,1.489,0,0,0,1.674-1.461c0-1.436-2.951-1.728-4.066-2.286a3.538,3.538,0,0,1-2.313-3.243c0-2.019,1.861-3.561,4.333-3.561a4.378,4.378,0,0,1,4.041,1.966c1.063,1.94-1.781,2.9-2.233,1.3-.213-.718-.9-.957-1.808-.957-.664,0-1.7.266-1.7,1.249,0,.425.187.771.851,1.089,2.074.957,5.635,1.356,5.635,4.439,0,2.472-2.153,3.774-4.332,3.774-2.34,0-3.908-.584-4.864-2.6"
        transform="translate(-273.593 -325.556)"
        fill="#242752"
      />
    </g>
  </svg>
);
let oseregio = (
  <svg xmlns="http://www.w3.org/2000/svg" width="78.571" height="25" viewBox="0 0 78.571 25">
    <defs>
      <linearGradient id="linear-gradient6" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#f991d6" />
        <stop offset="1" stopColor="#7e00b1" />
      </linearGradient>
    </defs>
    <rect width="78.571" height="25" transform="translate(0 0)" fill="none" />
    <g transform="translate(24.952 0.023)">
      <g transform="translate(0.893 13.661)">
        <path
          d="M102.719,227.322a1.063,1.063,0,0,0-1.871.795v3.84h-1.59v-6.371h1.553v.611a2.156,2.156,0,0,1,1.6-.685,1.924,1.924,0,0,1,1.5.6Z"
          transform="translate(-99.258 -223.201)"
          fill="#242752"
        />
        <path
          d="M107.635,229.266a1.315,1.315,0,0,0,1.394,1.419,1.763,1.763,0,0,0,1.443-.6l.966.941a3.032,3.032,0,0,1-2.421,1c-1.5,0-2.947-.684-2.947-3.265,0-2.079,1.125-3.253,2.776-3.253a2.741,2.741,0,0,1,2.776,3.045v.709Zm2.274-1.773a1.187,1.187,0,0,0-2.128,0,1.753,1.753,0,0,0-.146.685h2.421A1.753,1.753,0,0,0,109.909,227.493Z"
          transform="translate(-101.204 -223.201)"
          fill="#242752"
        />
        <path
          d="M117.987,234.366a2.979,2.979,0,0,1-2.311-.831l.991-1a1.62,1.62,0,0,0,1.247.489A1.2,1.2,0,0,0,119.2,231.7v-.648a1.867,1.867,0,0,1-1.516.636,2.121,2.121,0,0,1-1.516-.562,5.241,5.241,0,0,1,0-5.051,2.148,2.148,0,0,1,1.529-.563,1.864,1.864,0,0,1,1.553.673v-.6h1.541v6.139A2.586,2.586,0,0,1,117.987,234.366Zm.159-7.423c-.942,0-1.04.807-1.04,1.651s.1,1.663,1.04,1.663,1.051-.819,1.051-1.663S119.087,226.943,118.146,226.943Z"
          transform="translate(-103.903 -223.201)"
          fill="#242752"
        />
        <path
          d="M125.357,223.572v-1.3h1.626v1.3Zm.024,7.46v-6.371h1.59v6.371Z"
          transform="translate(-106.715 -222.276)"
          fill="#242752"
        />
        <path
          d="M134.392,231.247a2.641,2.641,0,0,1-1.981.783,2.612,2.612,0,0,1-1.969-.783,4.61,4.61,0,0,1,0-4.952,2.612,2.612,0,0,1,1.969-.783,2.641,2.641,0,0,1,1.981.783,4.61,4.61,0,0,1,0,4.952Zm-1.235-4.023a1,1,0,0,0-.746-.281.976.976,0,0,0-.734.281,2.153,2.153,0,0,0-.366,1.541,2.187,2.187,0,0,0,.366,1.554.976.976,0,0,0,.734.281,1,1,0,0,0,.746-.281,2.187,2.187,0,0,0,.367-1.554A2.153,2.153,0,0,0,133.157,227.224Z"
          transform="translate(-107.962 -223.201)"
          fill="#242752"
        />
      </g>
      <path
        d="M136.621,207.537V209.4c0,.824-.585,1.2-1.356,1.2h-5.343v.93a2.113,2.113,0,0,0,2.206,2.047,1.638,1.638,0,0,0,1.6-.851A1.291,1.291,0,1,1,135.9,214.1a4.571,4.571,0,0,1-4.014,1.807,4.467,4.467,0,0,1-4.705-4.386v-4.04a4.733,4.733,0,0,1,9.436.054m-6.7.877h3.986v-.93a1.994,1.994,0,0,0-3.986,0Z"
        transform="translate(-106.344 -203.151)"
        fill="#242752"
      />
      <path
        d="M98.008,211.5v-3.934a4.728,4.728,0,0,1,9.436,0V211.5a4.728,4.728,0,0,1-9.436,0m2.711-3.934V211.5a1.942,1.942,0,0,0,2.02,1.967,1.919,1.919,0,0,0,1.993-1.967v-3.934a2,2,0,0,0-1.993-2.02,2.019,2.019,0,0,0-2.02,2.02"
        transform="translate(-98.008 -203.151)"
        fill="#242752"
      />
      <path
        d="M113,213.412c-.771-1.435,1.674-2.5,2.392-1.063a2.361,2.361,0,0,0,2.392,1.355,1.489,1.489,0,0,0,1.674-1.461c0-1.435-2.951-1.727-4.066-2.286a3.539,3.539,0,0,1-2.312-3.243c0-2.02,1.86-3.562,4.333-3.562a4.377,4.377,0,0,1,4.04,1.966c1.063,1.941-1.781,2.9-2.233,1.3-.213-.719-.9-.958-1.807-.958-.665,0-1.7.266-1.7,1.249,0,.425.186.771.851,1.089,2.074.957,5.635,1.356,5.635,4.439,0,2.472-2.154,3.774-4.333,3.774-2.34,0-3.907-.584-4.864-2.6"
        transform="translate(-102.248 -203.151)"
        fill="#242752"
      />
    </g>
    <circle cx="11.219" cy="11.219" r="11.219" transform="translate(0 0)" fill="url(#linear-gradient6)" />
    <g transform="translate(7.637 6.455)">
      <g transform="translate(2.423)">
        <path
          d="M78.053,214.445a1.16,1.16,0,1,0-.864-.864A1.162,1.162,0,0,0,78.053,214.445Z"
          transform="translate(-77.159 -212.156)"
          className="circle-icon"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          d="M81.487,215.861v.814h.521v-.922a.26.26,0,0,0-.078-.186l-.476-.476a.261.261,0,1,0-.369.369Z"
          transform="translate(-78.259 -212.973)"
          className="circle-icon"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
      <rect
        width="7.165"
        height="1.122"
        rx="0.384"
        transform="translate(0 4.147)"
        className="circle-icon"
        fill="#fff"
      />
      <path
        d="M79.538,216.964H76.793c-.492,0,.28-.866.28-.866a.212.212,0,0,1,.161-.074H79.1a.214.214,0,0,1,.162.074S80.03,216.964,79.538,216.964Z"
        transform="translate(-74.585 -213.261)"
        className="circle-icon"
        fill="#fff"
        fillRule="evenodd"
      />
    </g>
    <rect width="3.595" height="3.834" rx="0.759" transform="translate(9.401 12.189)" fill="#fff" />
  </svg>
);
let osewyzwanie = (
  <svg xmlns="http://www.w3.org/2000/svg" width="78.571" height="25" viewBox="0 0 78.571 25">
    <defs>
      <linearGradient id="linear-gradient7" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#b4d500" />
        <stop offset="1" stopColor="#00b800" />
      </linearGradient>
    </defs>
    <rect width="78.571" height="25" transform="translate(0 0)" fill="none" />
    <g transform="translate(0 0.025)">
      <circle cx="11.223" cy="11.223" r="11.223" fill="url(#linear-gradient7)" />
      <g transform="translate(6.587 6.162)">
        <path
          d="M248.539,459.7a.309.309,0,0,1-.309-.308l-.01-3.007a.309.309,0,0,1,.308-.311h0a.31.31,0,0,1,.309.309l.01,3.007a.308.308,0,0,1-.308.31Z"
          transform="translate(-244.974 -456.079)"
          className="circle-icon"
          fill="#fff"
        />
        <path
          d="M253.461,458.247h-3.55v-1.754h3.55l-1.1.865Z"
          transform="translate(-245.457 -456.197)"
          className="circle-icon"
          fill="#fff"
        />
        <path
          d="M250.734,466.918l-3.04-4.5a.534.534,0,0,0-.885,0l-3.04,4.5a.533.533,0,0,0,.442.832h6.081a.533.533,0,0,0,.442-.832Zm-2.278-1.379-.367-.171a.253.253,0,0,0-.262.03l-.221.171a.253.253,0,0,1-.387-.1l-.141-.324a.253.253,0,0,0-.394-.092l-.29.245a.253.253,0,0,1-.359-.034l-.131-.161a.254.254,0,0,1-.011-.3L247,463.2a.253.253,0,0,1,.416,0l1.351,1.964A.253.253,0,0,1,248.457,465.54Z"
          transform="translate(-243.676 -457.823)"
          className="circle-icon"
          fill="#fff"
        />
        <g transform="translate(6.433 5.79)">
          <path
            d="M256.218,467.579l-2.151-3.182a.482.482,0,0,0-.8,0l-.587.868,1.245,1.842a1.315,1.315,0,0,1,.141,1.224h1.751A.482.482,0,0,0,256.218,467.579Z"
            transform="translate(-252.682 -464.185)"
            className="circle-icon"
            fill="#fff"
          />
        </g>
      </g>
    </g>
    <g transform="translate(25.017 0)">
      <g transform="translate(0.049 13.66)">
        <path
          d="M276.786,476.25h-1.308l-1.333-4-1.333,4H271.5l-1.956-6.371h1.688l1.027,3.938,1.3-3.938h1.161l1.309,3.938,1.027-3.938h1.676Z"
          transform="translate(-269.547 -467.495)"
          fill="#242752"
        />
        <path
          d="M285.753,477.278a2.265,2.265,0,0,1-.477.819,1.841,1.841,0,0,1-1.37.489h-.624v-1.431h.367a.687.687,0,0,0,.8-.575l.269-.783-2.176-5.919h1.676l1.321,3.938,1.271-3.938h1.676Z"
          transform="translate(-273.258 -467.495)"
          fill="#242752"
        />
        <path
          d="M291.556,476.25V475.04l2.825-3.73h-2.666v-1.431h4.7v1.211l-2.861,3.729h2.861v1.431Z"
          transform="translate(-275.835 -467.495)"
          fill="#242752"
        />
        <path
          d="M306.411,476.25H305.1l-1.333-4-1.333,4h-1.309l-1.956-6.371h1.688l1.027,3.938,1.3-3.938h1.162l1.308,3.938,1.028-3.938h1.675Z"
          transform="translate(-278.011 -467.495)"
          fill="#242752"
        />
        <path
          d="M316.427,476.221v-.55a1.936,1.936,0,0,1-1.565.611,2.172,2.172,0,0,1-1.626-.563,2.038,2.038,0,0,1-.526-1.431c0-1.015.7-1.846,2.177-1.846h1.5v-.318c0-.7-.343-1-1.186-1a1.369,1.369,0,0,0-1.223.526l-1.015-.991a2.679,2.679,0,0,1,2.3-.88c1.786,0,2.715.758,2.715,2.25v4.194Zm-.036-2.69h-1.26c-.574,0-.893.269-.893.721s.294.734.918.734a1.258,1.258,0,0,0,1-.306,1.047,1.047,0,0,0,.233-.807Z"
          transform="translate(-281.879 -467.466)"
          fill="#242752"
        />
        <path
          d="M326.187,476.221V472.37a1.062,1.062,0,1,0-2.115,0v3.851h-1.59V469.85h1.554v.588a2.177,2.177,0,0,1,1.589-.661,2.07,2.07,0,0,1,1.5.562,2.363,2.363,0,0,1,.648,1.81v4.072Z"
          transform="translate(-284.671 -467.466)"
          fill="#242752"
        />
        <path
          d="M332.254,467.837v-1.3h1.627v1.3Zm.025,7.459v-6.371h1.59V475.3Z"
          transform="translate(-287.463 -466.541)"
          fill="#242752"
        />
        <path
          d="M338.116,473.531a1.316,1.316,0,0,0,1.394,1.419,1.763,1.763,0,0,0,1.443-.6l.966.941a3.032,3.032,0,0,1-2.421,1c-1.5,0-2.947-.685-2.947-3.265,0-2.079,1.125-3.253,2.776-3.253a2.74,2.74,0,0,1,2.776,3.045v.709Zm2.274-1.774a1.187,1.187,0,0,0-2.128,0,1.756,1.756,0,0,0-.146.685h2.421A1.757,1.757,0,0,0,340.39,471.758Z"
          transform="translate(-288.691 -467.466)"
          fill="#242752"
        />
      </g>
      <path
        d="M308.092,451.8v1.861c0,.824-.585,1.2-1.356,1.2h-5.343v.93a2.113,2.113,0,0,0,2.206,2.047,1.639,1.639,0,0,0,1.6-.851,1.291,1.291,0,1,1,2.179,1.382,4.57,4.57,0,0,1-4.013,1.807,4.467,4.467,0,0,1-4.705-4.386v-4.04a4.733,4.733,0,0,1,9.436.054m-6.7.877h3.987v-.93a1.994,1.994,0,0,0-3.987,0Z"
        transform="translate(-277.815 -447.417)"
        fill="#242752"
      />
      <path
        d="M269.478,455.764v-3.933a4.728,4.728,0,0,1,9.436,0v3.933a4.728,4.728,0,0,1-9.436,0m2.711-3.933v3.933a1.942,1.942,0,0,0,2.02,1.968,1.92,1.92,0,0,0,1.994-1.968v-3.933a2,2,0,0,0-1.994-2.021,2.019,2.019,0,0,0-2.02,2.021"
        transform="translate(-269.478 -447.417)"
        fill="#242752"
      />
      <path
        d="M284.466,457.678c-.771-1.435,1.674-2.5,2.392-1.063a2.362,2.362,0,0,0,2.392,1.355,1.489,1.489,0,0,0,1.675-1.461c0-1.435-2.951-1.727-4.067-2.286a3.54,3.54,0,0,1-2.312-3.243c0-2.02,1.861-3.562,4.333-3.562a4.379,4.379,0,0,1,4.041,1.966c1.063,1.941-1.781,2.9-2.234,1.3-.212-.718-.9-.957-1.807-.957-.664,0-1.7.266-1.7,1.249,0,.425.186.771.851,1.089,2.074.957,5.635,1.356,5.635,4.439,0,2.472-2.153,3.774-4.332,3.774-2.34,0-3.908-.584-4.865-2.6"
        transform="translate(-273.718 -447.417)"
        fill="#242752"
      />
    </g>
  </svg>
);
let mojeose = (
  <svg xmlns="http://www.w3.org/2000/svg" width="78.571" height="25" viewBox="0 0 78.571 25">
    <defs>
      <linearGradient id="linear-gradient8" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#00d4b3" />
        <stop offset="1" stopColor="#004386" />
      </linearGradient>
    </defs>
    <rect width="78.571" height="22.857" transform="translate(0 2.143)" fill="none" />
    <g transform="translate(0 0.178)">
      <circle cx="11.168" cy="11.168" r="11.168" fill="url(#linear-gradient8)" />
      <path
        d="M417.589,579.163l-.019-.019a5.67,5.67,0,0,0-4.769,1.719,8.41,8.41,0,0,0-1.154,1.521l-1.709.1-.994,1.082.594.594,1.367-.068c-.091.23-.516,1.013-.584,1.229l1.2,1.09c.229-.072.92-.5,1.164-.6l-.071,1.424.594.594,1.082-.994.1-1.775a8.365,8.365,0,0,0,1.479-1.128A5.673,5.673,0,0,0,417.589,579.163Zm-2.912,2.892a.91.91,0,1,1,1.288,0A.911.911,0,0,1,414.677,582.055Z"
        transform="translate(-402.328 -572.701)"
        className="circle-icon"
        fill="#fff"
      />
      <rect
        width="0.595"
        height="1.209"
        transform="translate(6.94 12.541) rotate(45)"
        className="circle-icon"
        fill="#fff"
      />
      <rect
        width="0.595"
        height="1.209"
        transform="translate(8.728 14.329) rotate(45)"
        className="circle-icon"
        fill="#fff"
      />
      <rect
        width="0.595"
        height="2.674"
        transform="translate(7.848 13.432) rotate(45)"
        className="circle-icon"
        fill="#fff"
      />
    </g>
    <g transform="translate(24.857 0)">
      <path
        d="M441.814,576.473v-3.811a1.05,1.05,0,1,0-2.094-.036v3.846h-1.572v-3.811a1.051,1.051,0,1,0-2.092,0v3.811h-1.574v-6.3h1.538v.581a2.2,2.2,0,0,1,1.572-.653,1.953,1.953,0,0,1,1.67.8,2.4,2.4,0,0,1,1.886-.8,2.2,2.2,0,0,1,1.549.556,2.374,2.374,0,0,1,.69,1.791v4.028Z"
        transform="translate(-434.482 -567.811)"
        fill="#242752"
      />
      <path
        d="M453.569,575.772a2.611,2.611,0,0,1-1.96.774,2.584,2.584,0,0,1-1.948-.774,4.562,4.562,0,0,1,0-4.9,2.582,2.582,0,0,1,1.948-.774,2.608,2.608,0,0,1,1.96.774,4.558,4.558,0,0,1,0,4.9Zm-1.222-3.98a.994.994,0,0,0-.738-.279.966.966,0,0,0-.726.279,2.129,2.129,0,0,0-.363,1.524,2.162,2.162,0,0,0,.363,1.536.966.966,0,0,0,.726.279.993.993,0,0,0,.738-.279,2.158,2.158,0,0,0,.364-1.536A2.125,2.125,0,0,0,452.347,571.792Z"
        transform="translate(-438.615 -567.811)"
        fill="#242752"
      />
      <path
        d="M457.788,577.917h-.9v-1.331h.605c.4,0,.556-.194.556-.569v-6.763h1.573v6.86A1.713,1.713,0,0,1,457.788,577.917Zm.241-9.739V566.9h1.609v1.282Z"
        transform="translate(-440.885 -566.896)"
        fill="#242752"
      />
      <path
        d="M464.285,573.812a1.3,1.3,0,0,0,1.38,1.4,1.744,1.744,0,0,0,1.427-.593l.956.931a3,3,0,0,1-2.4.992c-1.489,0-2.916-.677-2.916-3.23,0-2.056,1.114-3.218,2.746-3.218a2.711,2.711,0,0,1,2.746,3.011v.7Zm2.251-1.754a1.175,1.175,0,0,0-2.106,0,1.766,1.766,0,0,0-.145.677h2.4A1.739,1.739,0,0,0,466.535,572.058Z"
        transform="translate(-442.555 -567.811)"
        fill="#242752"
      />
      <g transform="translate(0.069 11.884)">
        <path
          d="M472.794,587.875v1.841c0,.816-.579,1.184-1.342,1.184h-5.287v.92a2.091,2.091,0,0,0,2.183,2.026,1.623,1.623,0,0,0,1.579-.841,1.277,1.277,0,1,1,2.156,1.367,4.522,4.522,0,0,1-3.972,1.789,4.421,4.421,0,0,1-4.656-4.341v-4a4.684,4.684,0,0,1,9.339.053m-6.629.868h3.946v-.92a1.974,1.974,0,0,0-3.946,0Z"
          transform="translate(-442.829 -583.534)"
          fill="#242752"
        />
        <path
          d="M434.578,591.795V587.9a4.68,4.68,0,0,1,9.339,0v3.893a4.68,4.68,0,0,1-9.339,0m2.683-3.893v3.893a1.922,1.922,0,0,0,2,1.947,1.9,1.9,0,0,0,1.973-1.947V587.9a1.976,1.976,0,0,0-1.973-2,2,2,0,0,0-2,2"
          transform="translate(-434.578 -583.534)"
          fill="#242752"
        />
        <path
          d="M449.411,593.689c-.763-1.42,1.656-2.472,2.367-1.052a2.337,2.337,0,0,0,2.368,1.341,1.473,1.473,0,0,0,1.657-1.446c0-1.421-2.92-1.709-4.025-2.262a3.5,3.5,0,0,1-2.288-3.21c0-2,1.841-3.525,4.288-3.525a4.333,4.333,0,0,1,4,1.946c1.053,1.92-1.762,2.867-2.21,1.289-.21-.711-.894-.947-1.789-.947-.658,0-1.684.263-1.684,1.236,0,.421.184.763.841,1.078,2.053.947,5.577,1.341,5.577,4.394,0,2.446-2.131,3.736-4.288,3.736-2.316,0-3.867-.578-4.814-2.578"
          transform="translate(-438.774 -583.534)"
          fill="#242752"
        />
      </g>
    </g>
  </svg>
);

const brandList = [
  { name: 'OSElab', image: oselab, link: 'https://ose.gov.pl/ose-lab' },
  { name: 'OSEhero', image: osehero, link: 'https://osehero.pl/' },
  { name: 'OSEinspiracje', image: oseinspiracje, link: 'https://ose.gov.pl/ose-inspiracje' },
  { name: 'OSElektury', image: oselektury, link: 'https://lektury.gov.pl/' },
  { name: 'OSElabTRS', image: oselabtrs, link: 'https://ose.gov.pl/dla-technicznego-reprezentanta-szkoly' },
  { name: 'OSEregio', image: oseregio, link: 'https://oseregio.pl/' },
  { name: 'OSEwyzwanie', image: osewyzwanie, link: 'https://ose.gov.pl/konkurs-osewyzwanie' },
  { name: 'mojeOSE', image: mojeose, link: 'https://moje.ose.gov.pl/' }
];
const Brands = () => {
  return (
    <div className="brands-container">
      <div className="content-wrapper">
        {brandList &&
          brandList.map((brand, id) => (
            <div className="brand" key={id}>
              <a href={brand.link} target="_blank" rel="noopener noreferrer">
                {brand.image}
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Brands;
