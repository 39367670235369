import React from 'react';
import DocumentTitle from 'react-document-title';

const debug = process.env.NODE_ENV !== 'production';
const ErrorPage = props => (
  <div>
    <DocumentTitle title={'error'} />
    <div className="content">
      {debug ? (
        <div>
          <div className="text-center">
            <h1>Wystąpił błąd</h1>
          </div>
          <div>
            <p style={{ color: 'orange' }}>Szczegóły błędu:</p>
            <p style={{ color: 'red' }}>{props.error.message}</p>
          </div>
        </div>
      ) : (
        <div>
          <div className="text-center">
            <h1>Wystąpił nieoczekiwany problem</h1>
          </div>
          <div>
            <p style={{ color: 'orange' }}>Jeśli problem nie ustępuje prosimy o kontakt</p>
            <p>Pod adresem:</p>
            <a type="email" href="mailto:ose@nask.pl">
              ose@nask.pl
            </a>
          </div>
        </div>
      )}
    </div>
  </div>
);
export default ErrorPage;
