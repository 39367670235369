import React from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import { Cookies, withCookies } from 'react-cookie';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useSetState } from '../../hooks/Hooks';

const neverExpire = accepted => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  return accepted ? new Date(year + 1000, month, day) : new Date(year, month + 1, day);
};

const AcceptCookies = ({ cookies, cookieName, location }) => {
  /*  const [content, getContent] = useAxiosHook(
    api.cms.wordpressController,
    api.cms.page + 'polityka-cookies',
    'get',
    null
  ); */

  const [state, setState] = useSetState({
    visible: cookies.get(cookieName) === undefined
  });

  /*  useEffect(() => {
    getContent();
  }, []); */

  const onAccept = () => {
    cookies.set(cookieName, true, {
      path: '/',
      expires: neverExpire(true)
    });
    setState({ visible: false });
  };

  const onCancel = () => {
    cookies.set(cookieName, false, {
      path: '/',
      expires: neverExpire(false)
    });
    setState({ visible: false });
  };

  const { visible } = state;
  return (
    <Modal isOpen={visible} size="lg" className="cookies-box">
      <ModalHeader className="cookies-title">Drogi użytkowniku,</ModalHeader>
      <ModalBody>
        <p>
          przechodząc do serwisu poprzez kliknięcie odnośnika „Akceptuj” wyrażasz zgodę na przetwarzanie przez nas
          Twoich danych osobowych.
        </p>
        <div className="cookies-scrollable">
          <h3 className="text-left cookies-title">Polityka Cookies</h3>
          <p>
            Gdy korzystasz z naszych serwisów, gromadzimy informacje o Twojej wizycie i sposobie poruszania się w
            naszych serwisach. W tym celu stosujemy pliki cookies. Plik cookies zawiera dane informatyczne, które są
            umieszczone w Twoim urządzeniu końcowym - przeglądarce internetowej, z której korzystasz.
          </p>
          <p>
            Pliki cookies używane w naszych serwisach wykorzystywane są między innymi do bieżącej optymalizacji serwisów
            oraz ułatwiania Twojego korzystania z nich. Niektóre funkcjonalności dostępne w naszych serwisach mogą nie
            działać, jeżeli nie wyrazisz zgody na instalowanie plików cookies.
          </p>
          <p>
            Instalowanie plików cookies lub uzyskiwanie do nich dostępu nie powoduje zmian w Twoim urządzeniu ani w
            oprogramowaniu zainstalowanym na tym urządzeniu.
          </p>
          <p>
            Stosujemy dwa rodzaje plików cookies: sesyjne i trwałe. Pliki sesyjne wygasają po zakończonej sesji, której
            czas trwania i dokładne parametry wygaśnięcia określa używana przez Ciebie przeglądarka internetowa oraz
            nasze systemy analityczne. Trwałe pliki cookies nie są kasowane w momencie zamknięcia okna przeglądarki,
            głównie po to, by informacje o dokonanych wyborach nie zostały utracone. Pliki cookies aktywne długookresowo
            wykorzystywane są, aby pomóc nam wspierać komfort korzystania z naszych serwisów, w zależności od tego czy
            dochodzi do nowych, czy do ponownych odwiedzin serwisu.
          </p>
          <p>
            <span className="cookies-title">Do czego wykorzystujemy pliki cookies?</span>
          </p>
          <p>
            Pliki cookies wykorzystywane są w celach statystycznych oraz aby usprawnić działanie serwisów i zwiększyć
            komfort z nich korzystania, m.in:
          </p>
          <ul>
            <li>
              pozwalają sprawdzić jak często odwiedzane są poszczególne strony serwisów - dane te wykorzystujemy do
              optymalizacji serwisów pod kątem odwiedzających;
            </li>
            <li>poprawiają wydajność i efektywność serwisów dla korzystających.</li>
          </ul>
          <p>
            <span className="cookies-title">
              W jaki sposób możesz nie wyrazić zgody na instalowanie plików cookies za pomocą ustawień przeglądarki?
            </span>
          </p>
          <p>
            Jeśli nie chcesz, by pliki cookies były instalowane na Twoim urządzeniu, możesz zmienić ustawienia swojej
            przeglądarki w zakresie instalowania plików cookies. W każdej chwili możesz też usunąć z pamięci swojego
            urządzenia pliki cookies zapisane w trakcie przeglądania naszych serwisów. Pamiętaj jednak, że ograniczenia
            w stosowaniu plików cookies mogą utrudnić lub uniemożliwić korzystanie z tych serwisów.
          </p>
          <p>
            <span className="cookies-title">Wykorzystywane narzędzia firm trzecich</span>
          </p>
          <p>Niektóre pliki cookies są tworzone przez podmiot, z usług których korzystamy, np.</p>
          <ul>
            <li>
              Google Inc.
              <br />W naszych serwisach wykorzystujemy narzędzie Google Analytics do analizy ruchu na stronie
              internetowej oraz aktywności dotyczących jej przeglądania. Wykorzystujemy je w szczególności do celów
              statystycznych, aby sprawdzić jak często odwiedzane są poszczególne serwisy. Dane te wykorzystujemy
              również do optymalizacji i rozwoju serwisów. Więcej informacji na temat narzędzia Google Analytics
              znajdziesz na stronie:
              <br />
              <a href="https://policies.google.com/technologies/cookies" target="_blank" rel="noopener noreferrer">
                https://policies.google.com/technologies/cookies
              </a>
            </li>
            <li>
              Youtube
              <br />
              Więcej informacji o zasadach plików cookies możesz znaleźć na:
              <br />
              <a href="https://policies.google.com/privacy?hl=pl&gl=pl" target="_blank" rel="noopener noreferrer">
                https://policies.google.com/privacy?hl=pl&gl=pl
              </a>
            </li>
          </ul>
        </div>
        <div className="cookies-modalfooter">
          <p className="cookies-txt">
            <strong>
              Wyrażam zgodę na podstawowe cookies, które są niezbędne, aby strona mogła działać prawidłowo.
            </strong>
          </p>
          <p className="cookies-txt">
            <strong>Czy chcesz pozwolić na zbieranie dodatkowo cookies funkcjonalnych?</strong>
          </p>
          <p>
            Wykorzystujemy je do analizowania wizyt na stronie internetowej. Pomoże nam to zrozumieć, jak często
            odwiedzane są poszczególne serwisy oraz pozwoli optymalizować i rozwijać stronę.{' '}
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="cookies-decline-btn btn-secondary" onClick={onCancel}>
          Blokuj
        </button>
        <button className="cookies-accept-btn btn-primary" onClick={onAccept}>
          Akceptuj
        </button>
      </ModalFooter>
    </Modal>
  );
};

AcceptCookies.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
  cookieName: PropTypes.string.isRequired
};

export default withRouter(withCookies(AcceptCookies));
