import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import mc from '../images/logos/mc.svg';
import nask from '../images/logos/nask.svg';
import lektury from '../images/logos/lektury_light.svg';
import facebook from '../images/logos/facebook.svg';
import twitter from '../images/logos/twitter.svg';
// import AppInfo from './utils/AppInfo';
import routeList from '../routes/routeList';
import Brands from './containers/Brands';

const Footer = () => (
  <div className="full-wrapper mt-auto">
    <footer>
      <div className="container-fluid footer">
        {/*<AppInfo />*/}
        <div className="content-wrapper d-flex flex-column flex-md-row justify-content-between">
          <div className="footer-column text-center text-md-left  col-md-4">
            <Link to="/" className="d-block">
              <img src={lektury} alt="Lektury.gov.pl" className="footer-logo-1" />
            </Link>
            <Link to={routeList.readings} className="d-block">
              <button className="btn-primary btn-txt footer-btn">Zobacz katalog</button>
            </Link>
          </div>
          <div className="footer-column text-center text-md-center col-md-4">
            <a href="https://www.gov.pl/web/cyfryzacja" target="_blank" rel="noopener noreferrer" className="d-block">
              <img src={mc} alt="Ministerstwo Cyfryzacji" className="footer-logo-2" />
            </a>
          </div>
          <div className=" footer-column text-center text-md-right  col-md-4">
            <a href="https://www.nask.pl" target="_blank" rel="noopener noreferrer" className="d-block">
              <img src={nask} alt="NASK" className="footer-logo-3" />
            </a>
            <div className="footer-social-icons">
              <div className="footer-social-findus">
                <p>Znajdź nas na:</p>
                <a href="https://www.facebook.com/OSEheropl/" target="_blank" rel="noopener noreferrer">
                  <img src={facebook} alt="Facebook" />
                </a>
                <a href="https://twitter.com/NASK_pl" target="_blank" rel="noopener noreferrer">
                  <img src={twitter} alt="Twitter" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Brands />
    </footer>
  </div>
);

export default withRouter(Footer);
