import React, { Component, Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
/* import Navbar from './components/menu/Navbar';
import Footer from './components/Footer'; */
import routes from './routes';
import ErrorBoundary from './components/errors/ErrorBoundary';
import { CookiesProvider } from 'react-cookie';
import GAListener from './components/GoogleAnalytics/GAListener';
import AcceptCookies from './components/cookies/AcceptCookies';
import Navbar from './components/menu/NavBar';
import Footer from './components/Footer';

const GA_COOKIE = 'GA_COOKIE_READINGS';

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <CookiesProvider>
          <GAListener cookieName={GA_COOKIE}>
            <Fragment>
              <AcceptCookies cookieName={GA_COOKIE} />
              <Navbar />
              <Switch>
                {routes.map((route, index) => (
                  <Route key={index} exact path={route.path} render={route.component} />
                ))}
                <Redirect to="/strona-nie-istnieje" exact />
              </Switch>
              <Footer />
            </Fragment>
          </GAListener>
        </CookiesProvider>
      </ErrorBoundary>
    );
  }
}

export default App;
