import React from 'react';
import AsyncLoader from '../components/utils/AsyncLoader';
import routeList from './routeList';

const HomeLoader = AsyncLoader(() => import('../components/pages/Home'));
const ReadingLoader = AsyncLoader(() => import('../components/pages/Reading'));
const ReadingsLoader = AsyncLoader(() => import('../components/pages/Readings'));
const CooperationLoader = AsyncLoader(() => import('../components/pages/Cooperation'));
const AuthorLoader = AsyncLoader(() => import('../components/pages/Author'));
const SearchLoader = AsyncLoader(() => import('../components/pages/SearchResults'));
const ProjectsLoader = AsyncLoader(() => import('../components/pages/Projects'));
const ProjectLoader = AsyncLoader(() => import('../components/pages/Project'));
const CategoryLoader = AsyncLoader(() => import('../components/pages/ReadingsCategoryList'));
const AddProjectLoader = AsyncLoader(() => import('../components/pages/AddProject'));
const ContactLoader = AsyncLoader(() => import('../components/pages/Contact'));
const NotFoundLoader = AsyncLoader(() => import('../components/pages/NotFound'));
const EpubLoader = AsyncLoader(() => import('../components/pages/EpubReader'));

const routes = [
  {
    path: routeList.home,
    component: props => <HomeLoader {...props} pageTitle="Lektury" />
  },
  {
    path: `${routeList.readings}/:no?` /* temp */,
    component: props => <ReadingsLoader {...props} pageTitle="Katalog lektur" />
  },
  {
    path: `${routeList.reading}/:slug`,
    component: props => <ReadingLoader {...props} />
  },
  {
    path: routeList.cooperation,
    component: props => <CooperationLoader {...props} pageTitle="Współpraca" />
  },
  {
    path: `${routeList.author}/:slug`,
    component: props => <AuthorLoader {...props} />
  },
  {
    path: `${routeList.search}/:search?`,
    component: props => <SearchLoader {...props} pageTitle="Wyniki wyszukiwania" />
  },
  {
    path: `${routeList.project}/:slug`,
    component: props => <ProjectLoader {...props} />
  },
  {
    path: `${routeList.projects}/:no?`,
    component: props => <ProjectsLoader {...props} pageTitle="Projekty szkolne" />
  },
  {
    path: `${routeList.epubReader}/:slug`,
    component: props => <EpubLoader {...props} pageTitle="Czytnik" />
  },
  {
    path: routeList.addProject,
    component: props => <AddProjectLoader {...props} pageTitle="Dodaj projekt szkolny" />
  },
  {
    path: routeList.contact,
    component: props => <ContactLoader {...props} pageTitle="Kontakt" />
  },
  {
    path: `${routeList.category}/:category/:id/:no?`,
    component: props => <CategoryLoader {...props} pageTitle="Lista lektur" />
  },
  {
    path: routeList.notFound,
    component: props => <NotFoundLoader {...props} pageTitle="Strona nie istnieje - Lektury" />
  }
];

export default routes;
