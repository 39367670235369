import React from 'react';
import book01_loader from '../../images/icons/book_01_loader.svg';
import book02_loader from '../../images/icons/book_02_loader.svg';
import book03_loader from '../../images/icons/book_03_loader.svg';
import circle_loader from '../../images/icons/books_background.svg';

/* todo - nowy loader */
const Loading = () => (
  <div className="my-auto">
    <div className="async-loader">
      <img src={book01_loader} alt="loader book" className="loader-first-book" />
      <img src={book02_loader} alt="loader book" className="loader-second-book" />
      <img src={book03_loader} alt="loader book" className="loader-third-book" />
      <img src={circle_loader} alt="loader book background" id="loader-circle" />
    </div>
  </div>
);

export default Loading;
