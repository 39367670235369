import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends Component {
  state = { hasError: null, error: null, info: null };

  componentDidCatch(error, info) {
    this.setState({
      hasError: this.props.history.location.pathname,
      error,
      info
    });
  }

  render() {
    if (this.state.hasError === this.props.history.location.pathname) {
      return <ErrorPage {...this.state} />;
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
