import { useEffect, useReducer, useRef } from 'react';
import { isEqual } from 'lodash';

/**
 * hook that behaves like this.setState() that can handle objects
 * only for objects
 * @param initialState
 * @returns {any[]}
 */
export const useSetState = initialState => {
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), initialState);
  return [state, setState];
};

/**
 * safe useSetState hook that will not be called if component is unmounted
 * only for objects
 * @param initialState
 * @returns {*[]}
 */
export const useSafeSetState = initialState => {
  const [state, setState] = useSetState(initialState);
  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);
  const safeSetState = (...args) => mountedRef.current && setState(...args);
  return [state, safeSetState];
};

/**
 * hook that will remember previous value
 * @param value
 * @returns {any}
 */
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const useDeepCompareMemoize = value => {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};
/**
 * useEffect like hooke that will deep compare observable input values
 * @param effect
 * @param inputs
 */
export const useDeepCompareEffect = (effect, inputs) => {
  useEffect(effect, useDeepCompareMemoize(inputs));
};
