import React, { Component, Fragment } from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import GoogleAnalytics from 'react-ga';
import { Cookies, withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

// tested in bool.test.js
const exp = /^(?:f(?:alse)?|no?|0+)$/i;
Boolean.parse = val => !exp.test(val) && !!val;
const Singleton = (() => {
  let instance;

  const createInstance = () => {
    GoogleAnalytics.initialize('UA-115000367-4');
    return GoogleAnalytics;
  };

  return {
    getInstance(cookieName) {
      if (!instance) {
        instance = createInstance(cookieName);
      }
      return instance;
    }
  };
})();

const trackPage = (page, props) => {
  const { cookies, cookieName, options } = props;
  if (Boolean.parse(cookies.get(cookieName))) {
    const GA = Singleton.getInstance(cookieName);
    GA.set({
      page,
      ...options
    });
    GA.pageview(page);
  }
};

class GAListener extends Component {
  componentDidMount() {
    this.props.history.listen(location => trackPage(location.pathname, this.props));
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

GAListener.defaultProps = {
  options: {}
};

GAListener.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
  options: PropTypes.object
};

export default withRouter(withCookies(GAListener));
