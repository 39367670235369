import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ScrollTop from './components/utils/ScrollToTop';
import * as serviceWorker from './serviceWorker';
import './styles/styles.scss';

ReactDOM.render(
  <BrowserRouter>
    <Fragment>
      <ScrollTop />
      <App />
    </Fragment>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
