import React, { useState } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Slider } from 'react-burgers';
import lektury from '../../images/logos/lektury.svg';
import menuitems from './menuItems';

const NavBar = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  const handleMobileMenu = () => {
    setActiveMenu(activeMenu => !activeMenu);
  };

  const closeMobileMenu = () => {
    setActiveMenu(false);
  };

  return (
    <div className="content-wrapper">
      <nav className="navbar navbar-expand-lg">
        <Link to="/" className="navbar-brand">
          <img src={lektury} alt="Lektury.gov.pl" />
        </Link>
        <Slider
          width={30}
          lineHeight={3}
          color="#034e97"
          active={activeMenu}
          onClick={handleMobileMenu}
          className="navbar-toggler"
          borderRadius={50}
        />
        <div className={`collapse navbar-collapse${activeMenu ? ' show' : ''}`}>
          <ul className="navbar-nav">
            {menuitems.map(item => (
              <li key={item.title} className="nav-item">
                <NavLink to={item.url} exact className="nav-link" onClick={closeMobileMenu}>
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default withRouter(NavBar);
